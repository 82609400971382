// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_nWCWJ{display:flex;justify-content:center;min-height:100vh;text-align:center}@media screen and (max-width:1024px){.container_nWCWJ{width:100%}}", ""]);
// Exports
exports.locals = {
	"container": "container_nWCWJ"
};
module.exports = exports;
